import React, { useEffect, useRef } from 'react';

import { LoadingOverlay } from '@dc3/ui/loading';
import { getEnvironmentVariable } from '@dc3/utils/constants';
import { useI18n } from '@dc3/utils/hooks';
import { getWebShopUrl } from '@dc3/utils/webshop';

interface ImpersonationProps {
  redirectTo: string;
  idToken: string;
  refreshToken: string;
  corporateIdentity: string;
}

export const Impersonation = ({
  redirectTo,
  idToken,
  refreshToken,
  corporateIdentity,
}: ImpersonationProps) => {
  const translate = useI18n();
  const formEl = useRef<HTMLFormElement>(null);
  const environment = (getEnvironmentVariable('ENV') ?? 'dev').toUpperCase();
  const webShopUrl = getWebShopUrl(corporateIdentity, environment);

  const action = `https://${webShopUrl}/auth/setauthcookies`;

  useEffect(() => formEl?.current?.submit(), []);

  return (
    <LoadingOverlay isLoading={true} message={translate('global.redirection')}>
      <form ref={formEl} id="redirectForm" method="post" action={action}>
        <input type="hidden" name="redirectTo" value={redirectTo} />
        <input type="hidden" name="idToken" value={idToken} />
        <input type="hidden" name="refreshToken" value={refreshToken} />
        <input type="hidden" name="impersonationIntent" value="true" />
      </form>
    </LoadingOverlay>
  );
};

import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@dc3/ui/accordion';
import { Icon } from '@dc3/ui/icon';
import styled from '@emotion/styled';
import { AccordionSummaryProps } from '@mui/material';
import { ThemeProps } from '@dc3/utils/theme';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

type AccordionSummaryPropsExtended = ThemeProps &
  AccordionSummaryProps & {
    hasErrorProperties: boolean;
  };

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'hasErrorProperties',
})(({ theme, hasErrorProperties }: AccordionSummaryPropsExtended) => ({
  pointerEvents: !hasErrorProperties ? 'none' : 'auto',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center',
    userSelect: 'text',
    justifyContent: 'space-between',
  },
  '& .MuiIconButton-root': {
    pointerEvents: 'auto',
  },
}));

export const StyledIconButton = styled(Icon)({
  pointerEvents: 'auto',
});

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingLeft: theme.spacing(6),
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

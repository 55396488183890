import React, { useState, MouseEvent, memo, useCallback } from 'react';
import { Text } from '@dc3/ui/text';
import {
  StyledAccordionSummary,
  StyledAccordion,
  StyledAccordionDetails,
  StyledIcon,
} from './ErrorDropdownAccordion.styles';
import { IconButton } from '@dc3/ui/buttons';
import { ErrorDropdownError } from '../ErrorDropdown.types';
import { PropertiesDisplay } from '@dc3/ui/properties-display';
import { Box } from '@dc3/ui/box';
import isEqual from 'react-fast-compare';

interface ErrorDropdownAccordionProps {
  errors: ErrorDropdownError[];
  closeDropdown: () => void;
}

export const ErrorDropdownAccordion = memo(
  ({ errors, closeDropdown }: ErrorDropdownAccordionProps) => {
    const [expandedIndex, setExpandedIndex] = useState<number | false>(false);

    const handleChange = useCallback(
      (index: number) => (event, newExpanded: boolean) => {
        setExpandedIndex(newExpanded ? index : false);
      },
      [],
    );

    const handleEdit = useCallback(
      ({ action, id }: ErrorDropdownError) =>
        (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          action(id);
          closeDropdown();
        },
      [],
    );

    const handleResolve = useCallback(
      (resolve: ErrorDropdownError['markAsSolved']) =>
        (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          resolve();
        },
      [],
    );

    return (
      <>
        {errors.map((error, index) => {
          const { errorProperties, description } = error;
          const hasErrorProperties =
            errorProperties && Object.keys(errorProperties).length > 0;

          return (
            <StyledAccordion
              key={index}
              disableGutters
              elevation={0}
              expanded={expandedIndex === index}
              onChange={hasErrorProperties && handleChange(index)}
            >
              <StyledAccordionSummary
                hasErrorProperties={hasErrorProperties}
                expandIcon={
                  hasErrorProperties ? (
                    <StyledIcon>arrow_right</StyledIcon>
                  ) : (
                    <StyledIcon>warning</StyledIcon>
                  )
                }
              >
                <Text variant="body2" mr={1}>
                  {description}
                </Text>
                <Box mr={-1}>
                  {error.markAsSolved && (
                    <IconButton
                      size="small"
                      onClick={handleResolve(error.markAsSolved)}
                    >
                      <StyledIcon>check</StyledIcon>
                    </IconButton>
                  )}
                </Box>
              </StyledAccordionSummary>
              {hasErrorProperties && (
                <StyledAccordionDetails>
                  <PropertiesDisplay properties={errorProperties} />
                </StyledAccordionDetails>
              )}
            </StyledAccordion>
          );
        })}
      </>
    );
  },
  isEqual,
);

ErrorDropdownAccordion.displayName = 'ErrorDropdownAccordion';

import React from 'react';
import { ErrorPage as ErrorComponent } from '@dc3/ui/error-page';
import { NextPage, NextPageContext } from 'next';

/*
500 errors are handled both client-side and server-side by the Error component
pages/_error.js is only used in production.
In development you’ll get an error with the call stack to know where the error originated from.

This is an alternative way of handling server errors, instead of ErrorBoundaries but requires this page to be everywhere
*/

interface Props {
  statusCode: number | undefined;
  err: (Error & { statusCode?: number | undefined }) | null | undefined;
}

export const ErrorPage: NextPage<Props> = ({ statusCode, err }) => (
  <ErrorComponent
    statusCode={statusCode}
    error={err}
    onRetry={() => window?.location?.reload()}
    showHomeButton
    reportable
  />
);

ErrorPage.getInitialProps = (context: NextPageContext) => {
  const { res, err } = context;
  const statusCode = res && res.statusCode;
  return { statusCode, err };
};

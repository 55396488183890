import React from 'react';
import { Text } from '@dc3/ui/text';

interface Props {
  children: React.ReactNode;
}

export const PropertyText = ({ children }: Props) => (
  <Text color="textSecondary" variant="caption">
    {children || '-'}
  </Text>
);

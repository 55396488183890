import { Impersonation } from './Impersonation';
import { NextPage, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import React from 'react';

interface Props {
  idToken: string;
  refreshToken: string;
}

export const SetAuthCookiesPage: NextPage<Props> = ({
  idToken,
  refreshToken,
}) => {
  const router = useRouter();
  const { redirectto: redirectTo, corporateIdentity } = router.query;
  const decodedRedirectTo = decodeURIComponent(redirectTo as string);
  return (
    <Impersonation
      redirectTo={decodedRedirectTo}
      idToken={idToken}
      refreshToken={refreshToken}
      corporateIdentity={corporateIdentity as string}
    />
  );
};

export const getServerSideProps = (ctx: NextPageContext) => {
  const cookieValues = parseCookies(ctx);
  const idToken = cookieValues.idToken;
  const refreshToken = cookieValues.refreshToken;

  return { props: { idToken, refreshToken } };
};

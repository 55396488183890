import React, { useState } from 'react';
import { Icon } from '@dc3/ui/icon';
import { StyledAlert, StyledButton } from './ErrorDropdown.styles';
import { useI18n } from '@dc3/utils/hooks';
import { Box } from '@dc3/ui/box';
import { ErrorDropdownAccordion } from './ErrorDropdownAccordion/ErrorDropdownAccordion';
import { Popover } from '@dc3/ui/popover';
import { ErrorDropdownError } from './ErrorDropdown.types';
import { isDropdownErrorNonInteractive } from './ErrorDropdown.utils';

interface ErrorDropdownProps {
  errors: ErrorDropdownError[];
}

export const ErrorDropdown = ({ errors }: ErrorDropdownProps) => {
  const translate = useI18n('ui');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!errors.length) {
    return null;
  }

  const isOneError = errors.length === 1;
  const isNonInteractive = isDropdownErrorNonInteractive(errors[0]);

  if (isOneError && isNonInteractive) {
    return (
      <StyledAlert
        severity="error"
        variant="filled"
        icon={<Icon>warning</Icon>}
      >
        {errors[0].description}
      </StyledAlert>
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box mx={1}>
      <StyledButton
        id="error-dropdown"
        aria-controls={open ? 'error-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        color="error"
        startIcon={<Icon>warning</Icon>}
        endIcon={<Icon>arrow_drop_down</Icon>}
      >
        {translate('failedValidation', { itemCount: errors?.length })}
      </StyledButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ErrorDropdownAccordion errors={errors} closeDropdown={handleClose} />
      </Popover>
    </Box>
  );
};

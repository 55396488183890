import React from 'react';
// required so that the Error component is always centered regardless off its wrapper
import { Global } from '@emotion/react';
import { Layout, PageCard } from '@dc3/ui/layout';
import { Error, ErrorFallbackProps } from '@dc3/ui/error';
import { resetCSS, globalCSS } from '@dc3/utils/theme';

const ErrorPage = (props: ErrorFallbackProps) => (
  <>
    <Global styles={[resetCSS, globalCSS]} />

    <Layout headerTitle="Error">
      <PageCard>
        <Error {...props} />
      </PageCard>
    </Layout>
  </>
);

export default ErrorPage;

import { Box } from '@dc3/ui/box';
import styled from '@emotion/styled';

export const StyledPropertiesItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

export const StyledProperties = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

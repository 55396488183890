import React from 'react';
import MaterialPopover from '@mui/material/Popover';
import { Box } from '@mui/material';

export const Popover = MaterialPopover;

interface PopoverWrapperType {
  targetElement: React.ReactElement;
  content: React.ReactElement;
}

export const PopoverWrapper = ({
  targetElement,
  content,
}: PopoverWrapperType) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Box
        display="inline-flex"
        aria-owns={isOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {targetElement}
      </Box>
      <Popover
        id="mouse-over-popover"
        // Fix for popover automatically calling onMouseLeave
        // https://mui.com/components/popover/
        style={{ pointerEvents: 'none' }}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </>
  );
};

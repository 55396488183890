import React from 'react';
import MaterialAccordion from '@mui/material/Accordion';
import MaterialAccordionSummary, {
  AccordionSummaryProps as MaterialAccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MaterialAccordionDetails from '@mui/material/AccordionDetails';
import { Icon } from '@dc3/ui/icon';

interface AccordionSummaryProps extends MaterialAccordionSummaryProps {
  children?: React.ReactNode;
}

export const AccordionSummary = ({
  children,
  ...props
}: AccordionSummaryProps) => (
  <MaterialAccordionSummary expandIcon={<Icon>expand_more</Icon>} {...props}>
    {children}
  </MaterialAccordionSummary>
);

export {
  MaterialAccordion as Accordion,
  MaterialAccordionDetails as AccordionDetails,
};

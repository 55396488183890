import React from 'react';
import Document, { Head, Html, Main, NextScript } from 'next/document';
import { getEnvironmentVariable } from '@dc3/utils/constants';
import {
  ThirdPartyProvider,
  toggleAccessControlScript,
} from '@dc3/utils/providers';
import { createEmotionCache } from '@dc3/utils/theme';
import createEmotionServer from '@emotion/server/create-instance';

const mediaServer = getEnvironmentVariable('MEDIA_SERVER');
const isProduction = getEnvironmentVariable('ENV')?.toLowerCase() === 'prd';

const getFavicon = (icon: string) => `${mediaServer}/favicon/${icon}`;

// https://github.com/mui/material-ui/blob/2ac4439884fd0bbf4bc86143e2aa84261f6ff191/examples/nextjs-with-typescript/pages/_document.tsx
// `getInitialProps` belongs to `_document` (instead of `_app`),
// it's compatible with static-site generation (SSG).
class MyDocument extends Document {
  render() {
    const toggleConfigurationScript = !isProduction
      ? toggleAccessControlScript
      : '';

    return (
      <Html>
        <Head>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="googlebot" content="noindex,nofollow" />
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={getFavicon('apple-touch-icon.png')}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={getFavicon('favicon-32x32.png')}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={getFavicon('favicon-16x16.png')}
          />
          <link
            rel="mask-icon"
            href={getFavicon('safari-pinned-tab.svg')}
            color="#5bbad5"
          />

          <script
            dangerouslySetInnerHTML={{
              __html: toggleConfigurationScript,
            }}
          />
          <ThirdPartyProvider />
          {/* Inject MUI styles first to match with the prepend: true configuration. */}
          {(this.props as any).emotionStyleTags}
        </Head>

        <body>
          <div id="root-modal" />
          <Main />
          <NextScript />
        </body>
      </Html>
    );
  }
}

MyDocument.getInitialProps = async (ctx) => {
  // Resolution order
  //
  // On the server:
  // 1. app.getInitialProps
  // 2. page.getInitialProps
  // 3. document.getInitialProps
  // 4. app.render
  // 5. page.render
  // 6. document.render
  //
  // On the server with error:
  // 1. document.getInitialProps
  // 2. app.render
  // 3. page.render
  // 4. document.render
  //
  // On the client
  // 1. app.getInitialProps
  // 2. page.getInitialProps
  // 3. app.render
  // 4. page.render

  const originalRenderPage = ctx.renderPage;

  // You can consider sharing the same emotion cache between all the SSR requests to speed up performance.
  // However, be aware that it can have global side effects.
  const cache = createEmotionCache();
  const { extractCriticalToChunks } = createEmotionServer(cache);

  ctx.renderPage = () =>
    originalRenderPage({
      // eslint-disable-next-line react/display-name
      enhanceApp: (App: any) => (props) =>
        <App emotionCache={cache} {...props} />,
    });

  const initialProps = await Document.getInitialProps(ctx);
  // This is important. It prevents emotion to render invalid HTML.
  // See https://github.com/mui-org/material-ui/issues/26561#issuecomment-855286153
  const emotionStyles = extractCriticalToChunks(initialProps.html);
  const emotionStyleTags = emotionStyles.styles.map((style) => (
    <style
      data-emotion={`${style.key} ${style.ids.join(' ')}`}
      key={style.key}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: style.css }}
    />
  ));

  return {
    ...initialProps,
    // Styles fragment is rendered after the app and page rendering finish.
    styles: [
      ...React.Children.toArray(initialProps.styles),
      ...emotionStyleTags,
    ],
  };
};

export { MyDocument as Document };

import React from 'react';
import {
  StyledProperties,
  StyledPropertiesItem,
} from './PropertiesDisplay.styles';
import { PropertyText } from './PropertyText';

export interface Props {
  properties: Record<string, string>;
}

export const PropertiesDisplay = ({ properties }: Props) => (
  <StyledProperties>
    <StyledPropertiesItem>
      {Object.keys(properties).map((key) => (
        <PropertyText key={key}>{key}</PropertyText>
      ))}
    </StyledPropertiesItem>
    <StyledPropertiesItem>
      {Object.keys(properties).map((key) => (
        <PropertyText key={key}>{' : '}</PropertyText>
      ))}
    </StyledPropertiesItem>
    <StyledPropertiesItem>
      {Object.keys(properties).map((key) => (
        <PropertyText key={key}>{properties[key]}</PropertyText>
      ))}
    </StyledPropertiesItem>
  </StyledProperties>
);

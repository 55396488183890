import styled from '@emotion/styled';
import { Button } from '@dc3/ui/buttons';
import { Alert } from '@dc3/ui/alert';

export const StyledButton = styled(Button)`
  text-transform: none;
`;

export const StyledAlert = styled(Alert)({
  '&.MuiAlert-root': {
    alignItems: 'center',
    backgroundColor: 'rgb(253, 237, 237)',
    color: 'rgb(95, 33, 32)',
    fontWeight: 400,
  },
  '.MuiAlert-message, .MuiAlert-icon': {
    padding: 0,
  },
});
